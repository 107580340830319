/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const reactDomClient = require(`react-dom/client`)

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    reactDomClient.createRoot(container).render(element)
  }
}
