module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/mealpal-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6c0cc925160ebd9cad3474e4baeb4b9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["uk","us","au","ca","nz","sg"],"defaultLanguage":"us","fallbackLanguage":"us","redirect":false,"i18nextOptions":{"debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/privacy-policy","getLanguageFromPath":true,"languages":["us"]},{"matchPath":"/:lang?/cookie-policy","getLanguageFromPath":true,"languages":["us"]},{"matchPath":"/:lang?/faq","getLanguageFromPath":true,"languages":["us"]},{"matchPath":"/:lang?/terms-and-conditions","getLanguageFromPath":true,"languages":["us"]},{"matchPath":"/:lang?/merchant-agreement","getLanguageFromPath":true,"languages":["us"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
